// Theme Specific Variables
$primaryColor: #00a3e0;
$primaryDarkColor: #0082b3;
$primaryDarkerColor: #006286;
$primaryTextColor: #ffffff;

$textColor: #2e2e2e;


$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": #9C27B0,
    "red": #FF4032,
    "primary": $primaryColor
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';


//global
$fontFamily: Outfit, sans-serif !default;


:root {
    font-family: #{$fontFamily};
    --font-family: #{$fontFamily};
    --surface-a: #ffffff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #{$textColor};
    --text-color-secondary: #6c757d;
    --primary-color: #{$primaryColor};
    --primary-color-text: #{$primaryTextColor};
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground: #f8f9fa;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}