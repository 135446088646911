@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 100;
    font-display: auto;
    src: url('./fonts/Outfit-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 200;
    font-display: auto;
    src: url('./fonts/Outfit-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url('./fonts/Outfit-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('./fonts/Outfit-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url('./fonts/Outfit-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: url('./fonts/Outfit-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: bold;
    font-display: auto;
    src: url('./fonts/Outfit-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 800;
    font-display: auto;
    src: url('./fonts/Outfit-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url('./fonts/Outfit-Black.ttf') format('truetype');
}